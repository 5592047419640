import React from "react";
import "./Banner.scss";

function Banner() {
  return (
    <div className="Banner">
      <h1 className="title">
        Don Os
        <br />
        vuelve
        <br />a Lilac
      </h1>
    </div>
  );
}

export default Banner;
